@import './assets/css/variables.scss';

* {
    box-sizing: border-box;
}
body{
    font-family: 'Nunito', sans-serif;
    a, p, h1, h2, h3, h4, h5, h6, div, article, section, span {
        font-family: 'Nunito', sans-serif;
    }
}

.auth-wrapper{
    height: 100vh;
    background: $bgGray;
    .auth-bg{
        height: 50vh;
        border-radius: 0 0 30px 30px;
        overflow: hidden;
        width: 100%;
    }
}

.main-wrapper{
    display: flex;
    // padding: 15px;
    background-color: $bgGray;
    .sidebar-wrapper{
        // height: calc(100vh - 30px);
        height: 100vh;
        width: 250px;
        min-width: 250px;
        transition: all .3s;
        overflow: hidden;
        &.closed{
            width: 92px;
            min-width: 92px;
            transition: all .3s;
            .sidebar{
                ul{
                    li{
                        a{
                            span{
                                display: none;
                            }
                        }
                    }
                }
                .logout{
                    button{
                        span{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .content{
        // padding-left: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100vh;
        max-width: calc(100% - 250px);
        &.large {
            max-width: calc(100% - 92px);
        }
        .header-wrapper{
            background: linear-gradient(-195deg, #6a6a6a, #191919);
            padding: 15px 15px 12px 15px;
        }
        .content-body{
            height: 100%;
            overflow: hidden auto;
            padding: 10px;
        }
    }
}

.page-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding-top: 10px;
    .title{
        font-size: 28px;
        font-weight: 700;
    }
    .actions{
        display: flex;
        align-items: center;
        button{
            width: auto;
        }
        a{
            margin-left: 10px;
        }
    }
}

.form-wrapper{
    .form-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid $colorCCC;
        .actions{
            display: flex;
            align-items: center;
            .search{
                margin-right: 10px;
            }
        }
        .title{
            font-size: 28px;
            font-weight: 700;
        }
    }
    .input-field{
        margin-bottom: 10px;
        position: relative;
        .tick{
            position: absolute;
            top: 12px;
            right: 8px;
            svg{
                width: 30px;
                height: 30px;
                color: green;
            }
        }
        .error-message{
            font-size: 12px;
            color: $errorColor;
        }
        .valid{
            font-size: 12px;
            color: green;
        }
    }
    .form-actions{
        padding-top: 15px;
    }
}

@media(max-width: 600px) {
    .main-wrapper{
        .sidebar-wrapper{
            width: 72px;
            min-width: 72px;
            &.closed{
                width: 0;
                min-width: 0;
            }
        }
        .content{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            height: 100vh;
            max-width: calc(100% - 72px);
            &.large {
                max-width: 100%;
            }
        }
    }   
    .page-heading{
        display: block;
        padding: 0 10px;
        .title{
            font-size: 24px;
        }
        .actions{
            flex-wrap: wrap;
            .search {
                width: 100%;
                margin: 10px 0;
            }
            a {
                margin-left: 0;
                margin-right: 8px;
            }
        }
    }
}